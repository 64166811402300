@use "~/src/scss/mixins/breakpoints";

.offer-details {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px;

  > div {
    width: 50%;
  }
}

.offer-text {
  background-color: #faffea;
  font-family: 'Times New Roman';
  padding: 10px;
  margin-top: 1rem;
  position: relative;

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

hr {
  margin: 5px 10px;
  border: none;
  border-top: 1px solid #efefef;
}


.calculated-offer-table {
  padding: 20px;
  margin-top: 0;

  tr td:nth-child(2), tr th:nth-child(2) {
    opacity: 0.5;
  }

  td span {
    display: block;

    @include breakpoints.mobile {
      line-height: 1;
      min-width: 160px;
    }
  }

  .description {
    font-size: 0.85rem;
    opacity: 0.8;
    font-style: italic;

    @include breakpoints.mobile {
      line-height: 1;
    }
  }
}

.timer {
  flex-wrap: wrap;
  justify-content: start;
}

.hour-helper {
  display: flex;
  gap: 0 10px;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;

  @include breakpoints.mobile {
    margin-left: 0;
    margin-top: 10px;
  }
}

.details {
  line-height: 1.5;
  margin-top: 10px;

  > span {
    display: block;
  }
}

.tilbud-selector {
  align-items: center;
  justify-content: space-between;

  label {
    margin-left: 1rem;
  }
}
