.wo-popup-dialog {
  z-index: 1;
  background-color: white;
  padding: 10px 25px;
  box-shadow: 3px 4px 10px 0px #909090;
  .dialog-entry-header {
      text-align: left;
      color: #2b2b2b;
  }
}
