@use "~/src/scss/mixins/breakpoints";

.hero-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  > a, > div {
    background: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #d2d2d2;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
    text-decoration: none;
    color: #2b2b2b;
    transition: border-color linear 150ms;
    &:hover {
      border-color: #b1b1b1;
    }

    @include breakpoints.from-tablet {
      width: 200px;
    }

    > svg {
      width: 75px;
      height: 75px;
    }


    > span {
      font-style: italic;
      font-size: 0.85rem;
      color: #5a5a5a;
    }
  }
}
