﻿html, body {
  height: 100%;
  margin: 0;
}

html {
  box-sizing: border-box;
  overflow-y: scroll;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #2f2f2f;
  z-index: 1;
}

*, *:before, *:after {
  box-sizing: inherit;
}

h1 {
  margin: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg.icon {
  width: 1rem;
  height: 1rem;
}

span.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #216ba5;
  }
}

.content-margin-top {
  margin-top: 32px;
}

.json-pretty {
  line-height: 1.3;
  color: #66d9ef;


  .json-key {
    color: #272822;
  }

  .json-value {
    color: #A6E22E;
  }

  .json-string {
    color: #FD971F;
  }

  .json-boolean {
    color: #AC81Fe;
  }
}
.suggestion-span {
  font-style: italic;
  color: #b08e35;
  margin-top: 5px;
  display: inline-block;
  font-size: 0.85rem;
}

.address-display {
  display: flex;
  flex-direction: column;
}

.small-action-button {
  font-size: 10px;
  text-transform: uppercase;
  background: white;
  border: 1px solid #c8c8c8;
  padding: 2px;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: #e5e5e5;
  }
}

.page-keyfigures {
  .expanded {
    grid-column-start: 1;
    grid-column-end: span 5;
    grid-row: 1;
  }
}