@use "~/src/scss/mixins/breakpoints";

ol.breadcrumb {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;

  li {
    &:after {
      content: "\00BB";
      padding: 0 10px;

      @include breakpoints.from-tablet {
        padding: 0 5px;
      }
    }

    &:last-child {

      &:after {
        display: none;
      }
    }
  }
}
