@use "~/src/scss/mixins/breakpoints";

.workorder-invoices {
  display: flex;
  justify-content: space-between;

  @include breakpoints.to-laptop {
    flex-direction: column;

    .details-actions {
      margin-left: 0;
    }
  }

  .details-actions {
    max-width: 180px;
  }
}
