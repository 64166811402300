﻿.flex {
  display: flex;
}

.flex-center {
  @extend .flex;
  justify-content: center;
  align-items: center;
}

.flex-center-items {
  display: flex;
  align-items: center;
}

.no-wrap {
  white-space: nowrap;
}

.mirror {
  transform: scaleX(-1);
}

.comma-seperated-list > * {
  margin-right: 2px;

  &:after {
    content: ","
  }

  &:last-child {
    margin-right: 0;

    &:after {
      display: none;
    }
  }
}