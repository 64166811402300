.page-workorders-invoices-grid > * {
  display: grid;
  grid-template-columns: 65px 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2px 15px;
}

.page-workorders-invoices-grid-container {
  overflow-x: auto;
}

.page-workorders-invoices-grid {
  padding-bottom: 15px;
  min-width: 992px; /* for mobil-scrolling*/
}

.page-workorders-invoices-header {
  background-color: #606060;
  padding: 5px 20px;
  font-weight: bold;
  color: white;

  .right {
    text-align: right;
  }
}

.page-workorders-invoices-item, .page-workorders-invoices-total {
  padding: 5px 20px;

  .right {
    text-align: right;
  }
}

.total {
  font-weight: bold;
}

.page-workorders-invoices-total {
  font-weight: bold;

  :first-child {
    grid-column-start: 3;
  }
}
