.login-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../images/login_bg.jpg");
  z-index: 1;
  background-size: cover;
}

.login-form-container {
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  z-index: 2;
  background-color: rgba(255,255,255,0.9);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 4px 4px 20px #434343;
  min-width: 280px;

  .form-field-help-container {
    display: none;
  }

  .form-field {
    margin: 20px 0;
  }

  input {
    width: 100%;
    margin: 10px 0;
    padding: 5px;
    background-color: rgba(170, 170, 170, 0);
    border: 1px solid #aaa;
  }

  .buttons button {
    width: 100%;

    &:not([disabled]), &:hover:not([disabled]) {
      background-color: #3372a7;
      color: #fff;
    }
  }

  button {
    width: 100%;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  [role='alert'] {
    color: red;
    font-size: 0.85rem;
    font-style: italic;
  }

  .login-sso {
    max-width: 240px;
    margin-top: 16px;
    border-top: 1px solid #d7d7d7;

    > button {
      margin-top: 24px;
    }

    p {
      color: red;
      font-style: italic
    }
  }
}
