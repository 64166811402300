@use "~/src/scss/mixins/breakpoints";

.indicator-icons > span {
  margin: 0 2px;

  &.disabled {
    opacity: 0.2;
  }
}

// kontrollert
.state-bg-color-20 {
}

// i produksjon
.state-bg-color-120, .state-bg-color-130, .state-bg-color-260, .state-bg-color-300, .state-bg-color-310, .state-bg-color-150 {
  background-color: #FFFF00;
}

// ferdig
.state-bg-color-320 {
  background-color: #90EE90;
}

// levert
.state-bg-color-330 {
  background-color: #99CCFF;
}

// stopp
.state-bg-color-200 {
  background-color: #ffd1d1;
}

// reklamasjon
.state-bg-color-380 {
  background-color: #696969;
  color: #fff;

  a {
    color: #fff;
  }

  &:hover {
    color: #2b2b2b;
  }
}


// ekstern
.state-bg-color-260 {
}

.list-workorders {
  .is-rush a {
    color: red;
  }

  .is-recent {
    background-color: #FFFF99;
  }

  .eye {
    padding-left: 5px;
    height: 14px;
  }

  .state-indicator {
    .external {
      font-size: 0.85rem;
      padding-left: 5px;
      font-style: italic;
    }
  }

  .sync-icon {
    height: 13px;
    padding-left: 5px;
  }

  @include breakpoints.mobile {
    td {
      white-space: nowrap;
    }
  }
}

td.no-invoice svg {
  height: 10px;
  fill: #3c3c3c;
}

@keyframes warning-pulse {
  0% {
    fill: #ff5555;
  }
  100% {
    fill: #2f2f2f;
  }
}

.icon-warning {
  animation: warning-pulse;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.list-workorders .confirmed {
  color: #e53ee5;
  font-weight: 500;
}