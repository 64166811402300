.contacts-field {
  li {
    margin-bottom: 5px;
  }
  svg, button {
    cursor: pointer;
  }
}

.contacts-field-global-contacts {
  span {
    margin-left: 5px;
  }
}

.contacts-field-local-contacts {
  span {
    margin-left: 19px;
    margin-right: 10px;
  }
}

.contacts-field-add {
  margin-top: 10px;
}
