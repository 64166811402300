.change-password-component
{
    > div {
        max-width: 986px;
    }
    form {
        max-width: 270px;
    }

    ul {
        list-style: circle;
        padding-left: 21px;
    }
}