body.has-dialog {
  overflow: hidden;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  z-index: 2000;
}

.rl-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  z-index: 2010;
  display: flex;
  flex-direction: column;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  width: calc(100vw - 40px);
  max-width: 788px;

  &.dialog-fullscreen {
    top: 10px;
    left: 10px;
    height: calc(100vh - 20px);
    transform: none;
    max-width: inherit;

    .content {
      max-height: inherit;
      height: 100%;
    }
  }

  .header {
    padding: 24px;
    padding-bottom: 0;

    h3 {
      margin: 0
    }

    button {
      position: absolute;
      top: -12px;
      right: -12px;
      background: cadetblue;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      color: white;
      border: none;
      cursor: pointer;
    }
  }

  .content {
    flex-grow: 1;
    flex-grow: 1;
    margin: 24px;
    max-height: calc(80vh - 36px);
    overflow-y: auto;
  }

  .footer {
    border-top: 1px solid rgba(0,0,0,0.1);
    display: flex;
    justify-content: flex-end;
  }
}

.dialog-button {
  background-color: transparent;
  color: #343434;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  outline: 0;
  cursor: pointer;

  &[disabled] {
    color: #c3c3c3;
    cursor: default;
  }

  &:hover {
    background-color: #ebebeb;
  }
}

.container-has-padding {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

div[role="dialog"] div.footer {
  background-color: #fafafa;
  padding-bottom: 0;
}

.error-dialog-trace-info li span {
  display: inline-block;
  font-size: 12px;
  font-style: italic;

  &:first-child {
    width: 140px;
  }
}

.dialog .edit-form .form-field-information, [role="dialog"] .edit-form .form-field-information {
  display: none;
}