@use "~/src/scss/mixins/breakpoints";

.customer-type-selector-items {
  display: flex;

  @include breakpoints.mobile {
    flex-direction: column;
  }
}

.customer-type-selector-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #eaeaea;
  padding: 10px 20px;
  background: #fafafa;
  cursor: pointer;
  margin-right: 40px;

  svg {
    height: 90px;
    width: 90px;
  }

  &:hover {
    border: 1px solid #cdcdcd;
    background: #eaeaea;
  }

  @include breakpoints.mobile {
    margin-bottom: 10px;
  }
}

td .icon-person {
  margin-right: 4px;
}

td .icon-company {
  stroke: white;
  margin-right: 4px;
}

td .icon-gray {
    filter: grayscale(1);
}