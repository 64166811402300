.full-center-pending {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-pending {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    
 }