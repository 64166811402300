.page-statistics {
  .form-group {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      input {
        margin-right: 3px;
      }
    }
  }
}

.details-container {
  margin-bottom: 10px;
}

.details-container .presentation {
  position: absolute;
  right: 10px;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    opacity: 0.5;
    cursor: pointer;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.statistics-table {
  border-collapse: collapse;
  min-width: 300px;
  margin: 10px;
  border: 1px solid #eaeaea;

  caption {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }

  th {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    line-height: 1.4;
    background-color: #6c7ae0;
  }

  tbody td {
    color: gray;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  td {
    padding-right: 10px;
    text-align: right;
  }

  td:last-child {
    padding-right: 20px;
  }

  tr:nth-child(even) {
    background-color: #f8f6ff;
  }

  tfoot {
      td {
          font-weight: bold;
      }
  }
}
