@use "~/src/scss/mixins/breakpoints";

.user-type-selector-items {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoints.from-tablet {
    flex-direction: row;
  }
}

.user-type-selector-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border: 1px solid #eaeaea;
  padding: 10px 20px;
  background: #fafafa;
  cursor: pointer;
  margin-right: 40px;
  width: 160px;
  height: 160px;
  text-align: center;

  h4 {
    margin-top: 15px;
  }

  svg {
    height: 90px;
    width: 90px;
  }

  &:hover {
    border: 1px solid #cdcdcd;
    background: #eaeaea;
  }

  @include breakpoints.mobile {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}
