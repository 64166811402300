@use "~/src/scss/mixins/breakpoints";

.copy-to-clipboard {
  margin-left: 10px;
}

.external-order-information {
  .image-list {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-right: 5px;
    }

    div[role=img] {
      width: 212px;
      height: 120px;
      background-size: cover;
      border: 1px solid #f4f4f4;

      &:last-child {
        margin-right: 0
      }
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 10px;

    td {
      padding: 1px 0;
    }

    td:first-child {
      padding-right: 20px;
    }

    td:last-child {
      text-align: right;
    }
  }

  @include breakpoints.mobile {
    .external-order-information-status {
      flex-direction: column;
    }
  }
}

.external-to-late {
  color: #ff0076;
}

.last-list-by-list {
  line-height: 140%;

  a {
    min-width: 184px;
    display: inline-block;
  }
}



.workorder-transport-details {
  display: flex;
  justify-content: space-between;

  @include breakpoints.mobile {
    flex-direction: column-reverse;
  }
}

.workorder-transport-details .details-panel-item {
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }
  }

  .addressView, .kolliview {
    min-width: 320px;
  }

  @include breakpoints.from-laptop {
    flex-direction: row;

    > div {
      margin-left: 40px;
      margin-top: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.workorder-transport-list {
  margin-top: 40px;
}

.panel.details-container.wo-details-panel-rush {
  border: 1px solid red;
  background-color: #fff7f7;
}

.wo-d-confirmed {
  color: #e53ee5;
  font-weight: 500;
}