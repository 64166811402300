td.subtile {
  color: rgba(186, 186, 186, 0.87);
  font-style: italic;
}

ul.invoice-discrepancies li {
  background: #fffeb6;
  padding: 3px 10px;
  border-bottom: 1px solid #e9ff48;
  color: #646464;
  font-size: 15px;
  display: flex;

  &.information {
    background: #c4f5c1;
    color: #232323;
  }

  &.error {
    background: #ffbdbd;
    color: #232323;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    fill: #646464
  }

  span {
    margin-left: 10px;
  }
}

ul.invoice-discrepancies:not(:empty) {
  margin-bottom: 15px;
}

.invoice-state {
  margin-bottom: 20px;
}

.workorder-invoices .table-container td, .workorder-invoices .table-container th {
  &:first-child {
    padding-left: 0;
  }
}

.workorder-invoices .edit-form {
  margin-top: 30px;

  .form-field {
    background-color: inherit;
  }
}
