@use "~/src/scss/mixins/breakpoints";

$menu-on-right: 890px;

.details-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fafafa;
  flex-wrap: wrap;
  position: relative; // for audio recorder

  @media (min-width: $menu-on-right) {
    flex-wrap: nowrap;
    padding: 20px;
  }

  h4 {
    margin-top: 0;
  }
}

.details-blocks {
  display: flex;
  flex-wrap: wrap;

  @include breakpoints.from-tablet {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    width: 100%;

    &.details-blocks--condensed {
      width: inherit;
      grid-gap: 60px;
    }
  }

  @include breakpoints.from-laptop {
    grid-template-columns: minmax(28%, auto) minmax(28%, auto) minmax(28%, auto);
  }

  @media (min-width: 1620px) {
    &:not(.details-block-3) {
      grid-template-columns: minmax(20%, auto) minmax(20%, auto) minmax(20%, auto) minmax(20%, auto);
    }
  }
}

.details-actions {
  @media (min-width: $menu-on-right) {
    margin-left: 25px;
  }

  @include breakpoints.from-laptop {
    margin-left: 60px;
  }
}

.details-action {

  @media (min-width: 768px) and (max-width: $menu-on-right) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    > * {
      min-width: 20vw;
    }
  }
}
