@use "~/src/scss/mixins/breakpoints";

.form-container {
}

.edit-form-padding > h3, .edit-form-padding > div {
  padding-left: 20px;
  padding-right: 20px;
}

.edit-form-padding > h4 {
  padding: 3px 20px;
  background: #a4a4a4;
  margin: 0;
  color: #fff;
}

.edit-form .buttons {
  border-top: 1px solid #ebebeb;
  background-color: #fff;
}

.edit-form .form-field {
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  @include breakpoints.from-tablet {
    flex-direction: row;
  }

  &:first-of-type {
    border-top: none;
  }

  &:nth-child(even) {
    background-color: #fafafa;
  }

  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
}

.edit-form .form-field-help-container {
  user-select: none;
}

.edit-form .form-field-descripion {
  display: flex;
  justify-content: space-between;
  position: relative;

  @include breakpoints.from-tablet {
    flex-direction: column;
    flex-basis: 320px;
    align-items: flex-start;
    padding-right: 20px;
    justify-content: flex-start;
    flex-shrink: 0;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  .form-field-help-icon {
    @include breakpoints.from-tablet {
      display: none;
    }
  }
}

.edit-form .form-field-information {
  padding-left: 20px;
  max-width: 600px;
  font-size: 0.85em;
  display: flex;

  p {
    margin: 0;
  }

  .warning {
    color: red;
  }
}

.edit-form .form-field-label {
  font-weight: 600;
}

.edit-form .form-field-help-text {
  display: none;
  font-style: italic;
  font-size: 0.85rem;

  @include breakpoints.from-tablet {
    display: flex;
    margin-top: 5px;
  }

  &.visible {
    position: absolute;
    display: block;
    padding: 10px;
    max-width: 80%;
    right: 20px;
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    z-index: 1;
  }
}

.edit-form {

  input[type=text], input[type=email], input[type=number] {
    height: 36px;
  }

  input, select, textarea {
    padding: 0.45rem;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
    border-image: none;
    background-color: #fff;
  }

  select option:disabled {
    font-style: italic;
    color: #cacaca;
  }
}

.edit-form {
  .has-errors {
    input, select, textarea {
      border-color: #ba3a26;
    }
  }


  input:not([type='checkbox']):not([type='radio']):not([type='number']):not(.datepicker-input):not(.short-input):not(.input-currency),
  select:not(.short-select), textarea {
    width: 100%;

    @include breakpoints.from-tablet {
      min-width: 400px;
    }
  }

  textarea {
    @include breakpoints.from-tablet {
      min-height: 90px;
    }
  }

  input:not([type='checkbox']):not([type='radio']) {
    -webkit-appearance: none;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  input[type='radio'] {
    cursor: pointer;
  }

  [role='alert'] span {
    font-style: italic;
    color: #ba3a26;
    margin-top: 0.5rem;
    display: block;
    font-size: 0.85rem;
  }
}

.tilbakemelding {
  display: inline-flex;
  padding: 20px;
  margin: 20px 0;
  margin-left: 20px;

  &.tilbakemelding--varsel {
    background: #fff3f3;
    border: 1px solid red;
  }

  ul {
    margin-top: 10px;
    list-style: inside;
  }

  .tilbakemelding--icon {
    width: 45px;
    display: flex;
    align-items: flex-start;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .tilbakemelding--title {
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }
}
