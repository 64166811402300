@use "~/src/scss/mixins/breakpoints";

.header--userInfo {
  display: none;
  position: relative;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @include breakpoints.from-laptop {
    display: flex;
  }

  > span {
    margin: 0 5px;
  }

  ul {
    right: 0;
    top: 25px;
    position: absolute;
    display: none;
  }

  svg.caret {
    transform: rotate(180deg);
    transition: transform ease-in-out 50ms;
  }


  &[aria-expanded='true'] {
    ul {
      display: inline;
    }

    svg.caret {
      transform: rotate(0deg);
    }
  }
}

ul.context-menu {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: 0 2px 5px rgba(0,0,0,.15);
  font-size: 15px;
  outline: 0;
  padding: 2px 0;
  list-style: none;
  margin: 0;
  max-width: 250px;
  min-width: 125px;
  padding: 0;
  position: absolute;
  z-index: 1;

  > li.context-item {
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 6px 12px;

    &:hover {
      background-color: rgba(0,0,0,.05);
      outline: 0;
    }
  }

  > li.divider {
    height: 1px;
    margin: 4px 0;
    background-color: rgba(0,0,0,.15);
  }

  a, span {
    color: #2f2f2f;
    text-decoration: none;
  }
}
