.wo-preview {
  display: none;
  z-index: 1;
  background-color: white;
  padding: 2px;
  box-shadow: 3px 4px 10px 0px #909090;

  .wo-preview-unit {
    display: flex;
  }

  [role='img'], video {
    width: 224px;
    height: 126px;
    background-position: center;
    background-size: cover;
    position: relative;
    border: 1px solid #e6e6e6;
  }
}
