﻿td, th, .table-container td, .table-container th {

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.action {
    cursor: pointer;
    text-align: center;
  }
}

tr.overdue, tr.insolvent {
  background-color: #ffdede !important;
}
