.row-workorder, .row-order {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 5px;
  row-gap: 2px;

  > span.item {
    &-id {
      grid-column-start: span 1;
    }

    &-name {
      grid-column-start: span 5;
    }

    &-invoice {
      grid-column-start: span 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-days {
      grid-column-start: span 2;
    }

    &-state {
      grid-column-start: span 2;

      > span {
        width: 100%;
        display: inline-block
      }
    }

    &-created {
      grid-column-start: span 3;
    }

    &-weight {
      grid-column-start: span 3;
    }

    &-info {
      grid-column-start: span 6;

      span {
        white-space: pre-line;
      }
    }
  }
}

@media only screen and (min-width: 1324px) {
  .row-workorder, .row-order {
    display: grid !important;
    grid-template-columns: repeat(24, 1fr);
    column-gap: 10px;
    row-gap: 2px;

    > span.item {
      &-id {
        grid-column-start: span 1;
      }

      &-name {
        grid-column-start: span 4;
      }

      &-invoice {
        grid-column-start: span 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-days {
        grid-column-start: span 1;
      }

      &-state {
        grid-column-start: span 3;

        > span {
          width: 100%;
          display: inline-block
        }
      }

      &-created {
        grid-column-start: span 2;
        display: flex;
        align-items: center;
      }

      &-weight {
        grid-column-start: span 3;
        display: flex;
        justify-content: flex-end;
      }

      &-info {
        grid-column-start: span 7;
      }
    }
  }
}
