.video-stream-1 {
  aspect-ratio: 16/9;
  margin: 0 5px 10px 5px;
  border: 1px solid #d2d2d2;
  object-position: left;
  max-width: calc(100% - 10px);
  object-fit: cover;
  max-height: 262px;

  &.has-video {
    display: block;
  }
}
