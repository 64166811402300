.audit-table pre {
  white-space: pre-wrap;
}

.diff-row {
  display: flex;

  :first-child {
    width: 90px;
    padding-right: 15px;
  }

  :nth-child(2) {
    padding-right: 15px;
    width: 276px;
    word-break: break-word;
  }


  :nth-child(3) {
    max-width: 553px;
    word-break: break-all;
  }
}
