@use "~/src/scss/mixins/breakpoints";

.filter-container {
  background-color: #fafafa;
  padding-bottom: 5px;
}

.filter-toggles {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px 4px 10px;

  > button, > div {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

ul.filters {
  @include breakpoints.from-tablet {
    display: flex;
    flex-wrap: wrap;
    background-color: #fafafa;
  }
}


.filter-item {
  padding: 10px;
  border-bottom: 1px solid #f1efef;

  @include breakpoints.from-tablet {
    flex-grow: 0;
    border-right: 1px solid #cdcdcd;
    max-width: 25%;

    &:last-child {
      border-right: none;
    }
  }

  h4 {
    margin: 0 0 8px 0;
  }
}

.filter-item-item:not(:first-child) {
  margin-top: 10px;
}

button {
  svg:first-child {
    margin-right: 5px;
  }

  svg:last-child {
    margin-left: 5px;
  }
}
