﻿@use "mixins/breakpoints";

.ui-mobile {
  display: none;

  @include breakpoints.mobile {
    display: inherit;
  }
}

.ui-tablet {
  display: none;

  @include breakpoints.tablet {
    display: inherit;
  }
}

.ui-laptop {
  display: none;

  @include breakpoints.laptop {
    display: inherit;
  }
}


.ui-pc {
  display: none;

  @include breakpoints.pc {
    display: inherit;
  }
}


.ui-from-tablet {
  display: none;

  @include breakpoints.from-tablet {
    display: inherit;
  }
}

.ui-to-laptop {
  display: none;

  @include breakpoints.to-laptop {
    display: inherit;
  }
}

.ui-from-laptop {
  display: none;

  @include breakpoints.from-laptop {
    display: inherit;
  }
}
