.unit-images-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.unit-images-dialog-current {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;

  div[role='img'] {
    width: 100%;
    height: 100%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
  }

  video {    
    height: 100%;
    margin-right: auto;    
  }
}

.unit-images-dialog-selector-item {
  height: 120px;
  margin: 10px 0;
  display: flex;
}

.unit-images-dialog-selector-container {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0; /* remove scrollbar space */
    height: 0;
    background: transparent; /* optional: just make scrollbar invisible */
  }  
}

.unit-images-dialog-label {
  background-color: #000;
  color: #fff;
  text-orientation: upright;
  writing-mode: vertical-lr;
  text-transform: uppercase;
  padding: 5px;
}

.unit-images-dialog ul {
  display: flex;
  counter-reset: list;

  li {
    margin: 0 5px;
    padding-bottom: 10px; // to see marker line
    counter-increment: list;
  }
}

.unit-images-dialog-selector [role='img'], .unit-images-dialog-selector video {
  width: 213.3px;
  height: 120px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #dfdfdf;
  cursor: pointer;

  &:before {
    content: counter(list);
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: red;
    color: white;
    text-align: center;
  }

  &[aria-selected='true']:after {
    position: absolute;
    width: 100%;
    content: ' ';
    height: 5px;
    background-color: red;
    bottom: 0;
  }
}
