@use "~/src/scss/mixins/breakpoints";

.header-menu-toggle {
  cursor: pointer;
  padding-right: 20px;
  margin-top: 2px;
 

  @include breakpoints.from-laptop {
    display: none;
  }
}
