@use "~/src/scss/mixins/breakpoints";
@use "~/src/scss/mixins/variables";

.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .workspace {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    position: relative;

    @include breakpoints.laptop {
      flex-direction: column;
    }
  }

  main {
    flex-basis: 100%;
    flex-grow: 1;
    padding: variables.$page-vertical-margin-mobile;
    background-color: #efefef;
    overflow-x: hidden; // for table scroll on mobile/tablets

    @include breakpoints.pc {
      padding: variables.$page-vertical-margin-pc;
    }
  }
}
