@use "~/src/scss/mixins/breakpoints";

.day-overview-list {
  padding: 15px;

  .day-header {
    background: #5f5f5f;
    padding: 4px 4px;
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  .day-container {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  .day-item {
    padding: 2px 4px;
    border-bottom: 1px solid #e0e0e0;

    @include breakpoints.from-tablet {
      border-bottom: none;
    }

    &.day-item-hover {
      background-color: #ececec;
    }
  }

  .day-overview-serial {
    width: 85px;
    display: inline-block;
    padding-right: 5px;
  }

  .day-overview-customer {
    width: 320px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
    vertical-align: text-bottom;
  }

  .day-overview-weight {
    width: 75px;
    padding-right: 5px;
    display: inline-block;

    @include breakpoints.from-tablet {
      text-align: right;
    }
  }

  .day-header-date {
    display: inline-block;
    
    @include breakpoints.from-tablet {
      width: 400px;
    }
  }

  .day-header-weight {
    text-align: right;
    width: 90px;
    display: inline-block;
  }

  .day-header-count {
    text-align: right;
    width: 75px;
    display: inline-block;
  }

  .day-overview-delivery {
    width: 210px;
    padding-right: 5px;
    display: inline-block;
    text-align: right;
  }

  .day-overview-icons {
    width: 75px;
    display: inline-block;
    padding-left: 5px;
  }
}
