.list-orders tr.completed {
  font-style: italic;
  opacity: 0.8;
}


.list-orders .workorder-collection {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    z-index: 1;
    background: white;
  }

  svg:last-child {
    z-index: 0;
    margin-top: 4px;
    margin-left: -12px;
  }
}

.list-orders td.active-marker {
  border-left: 5px solid #005fffab;
  margin-left: 0px;
  padding-left: 5px;
}
