.edit-form .field-address {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }

  .field-address-picker, .field-address-country {
    margin-bottom: 10px;
  }

  .field-address-zip-city {
    display: flex;
  }

  .field-address-zip[type="text"] {
    flex-basis: 135px;
    flex-grow: 0;
    min-width: inherit;
    margin-right: 10px;
  }

  h4 {
    padding-left: 7px;
    margin-bottom: 4px;
    font-size: 14px;
    margin-top: 10px;
  }

  .field-address-contact {
    display: flex;

    .field-address-contact-name {
      margin-right: 10px;
    }

    .field-address-contact-phone {
      max-width: 230px;
    }
  }
}
