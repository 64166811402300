﻿/*
    0-768 mobile
    768-1366 - tablet
    1366-1920 - laptop
    1920- pc
*/
@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 768px) and (max-width: 1365px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1366px) and (max-width: 1919px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin from-tablet {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin to-laptop {
  @media (max-width: 1365px) {
    @content;
  }
}

@mixin from-laptop {
  @media (min-width: 1366px) {
    @content;
  }
}