.field-boolean-radio {
  display: flex;
  flex-direction: column;

  label {
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  input {
    margin-right: 5px;
  }
}
