@use "~/src/scss/mixins/breakpoints";

.details-action {
  .dropdown-button {
    margin: 10px 0;

    @include breakpoints.from-tablet {
      margin: 5px;
    }

    span {
      white-space: nowrap;
    }

    button {
      width: 100%;
    }
  }
}
