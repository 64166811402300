@use "~/src/scss/mixins/breakpoints";

.list-area {
  margin-top: 40px;
}

.table-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  margin: 10px 0;
  padding: 0 10px;

  @include breakpoints.from-tablet {
    justify-content: center;
  }

  @include breakpoints.from-tablet {
    justify-content: space-between;
  }

  > div, > nav {
    margin: 10px 0;
  }

  .rows {
    flex-grow: 1;

    @include breakpoints.from-tablet {
      flex-grow: 0;
      order: 0;
    }

    select {
      margin: 0 10px;
    }
  }

  .count {
    flex-grow: 0;
    display: flex;
    align-items: center;

    @include breakpoints.from-tablet {
      order: 2
    }
  }

  .paging {
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    @include breakpoints.from-tablet {
      flex-basis: inherit;
      order: 1
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      justify-content: space-between;

      @include breakpoints.from-tablet {
        width: 100%;
      }

      > li {
        padding: 0 10px;
        cursor: pointer;

        &[aria-disabled='true'] {
        }

        &[aria-selected='true'] {
          font-weight: bold;
        }
      }
    }
  }
}

.cell-action span {
  cursor: pointer;
}
