.dialog-entry-header {
    span {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    span.date {
        opacity: 0.4;
    }
}

.dialog-entry-text {
  background: #6fbced;
  color: #fff;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: inline-block;
  min-width: 263px;
  text-align: left;
}

.dialog-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}

.dialog-triangle {
  border-color: transparent transparent #6fbced transparent;
  margin-left: 25px;
}
.dialog-entry-header {
  margin-bottom: 3px;
}

.dialog-cell {
  opacity: 0.2;

  &.dialog-has-messages {
    opacity: 1;
  }
}

.chat-page-list {
  .form-field {
    padding: 10px 15px;
  }

  hr {
    margin: 0;
    margin-top: 7px;
  }
}