.office-lock-box {
  button {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    justify-content: flex-start;
    margin-top: 1rem;

    svg {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }

    &:first-child {
      margin-top: 2rem;
    }
  }
}
