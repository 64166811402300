.pallet-weight-field {
  input {
    width: 40px;
  }

  ion-icon {
    cursor: pointer;
    user-select: none;
    margin: 0 10px;
  }
}
