ul.filter-state {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;

  li {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    margin: 3px 0;
    white-space: nowrap;
  }

  input {
    margin-right: 5px;
    cursor: pointer;
  }

  label {
    min-width: 110px;
  }

  svg {
    height: 12px;
    cursor: pointer;
  }
}
