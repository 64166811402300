.field-notifications {
  td, th {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  td, th {
    padding: 0 5px;
    &:first-child {
      padding-left: 0;
    }
  }
  th {
    padding-bottom: 3px;
  }
}
