.filter-date-range {
  position: relative;
}

.filter-date-range ul.quick-date-selector {
  position: absolute;
  right: 0;
  top: -29px;
}

.filter-date-range ul.quick-date-selector span {
  border-radius: 53px;
  padding: 2px 6px;
  color: white;
  font-size: 12px;
  margin: 3px;
  cursor: pointer;
  user-select: none;
  background-color: #bababa;

  &:hover {
    background-color: #9a9a9a;
  }


  &:nth-child(5) {
    background-color: #9a9a9a;
    margin-left: 10px;

    &:hover {
      background-color: #7b7b7b;
    }
  }
}



.filter-date-range .input {
  margin: 5px 0;

  label {
    display: inline-block;
    width: 35px;
  }

  input {
    width: 144px;
  }
}
