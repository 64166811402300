@use "~/src/scss/mixins/variables";

footer {
  width: 100%;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: variables.$page-vertical-margin-mobile;
  background-color: #383838;
  color: #fff;
  a {
    color: #fff;
  }  
}
