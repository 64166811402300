﻿.react-datepicker {
  user-select: none;
}

// Hide dates outside month
.react-datepicker__day--outside-month {
  color: #fff !important; /* to add disabled feeling */
  pointer-events: none; /* to prevent click */
}

.react-datepicker__week-number--selected {
  background-color: inherit !important;
  color: #ccc !important;

  &:hover {
    background-color: inherit !important;
  }
}

.image-gallery.fullscreen-modal {
  z-index: 200;
}
