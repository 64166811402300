@use "~/src/scss/mixins/breakpoints";

.monthly-overview {
  .montly-month-navigator {
    display: flex;
    align-items: center;

    span {
      margin: 0 20px;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  h3 {
    margin-right: 50px;
  }

  .monthly-header {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    padding: 10px 20px 0 20px;

    button {
      background-color: transparent;
      border: 1px solid #a5a4a4;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;

      &:hover {
        background-color: #ececec;
      }

      &.previous svg {
        transform: rotate(180deg);
      }
    }
  }

  .container-with-actions {
    display: flex;

    @include breakpoints.mobile {
      flex-direction: column;
    }

    .table-container {
      flex-grow: 1;
    }

    .details-action-list {

      @include breakpoints.mobile {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @include breakpoints.from-tablet {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-basis: 200px;

        > * {
          min-width: 135px;
          margin-top: 10px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
