.boom-panel {
  padding: 10px;
  margin-bottom: 20px;
}

.boom-panel-header {
  font-weight: bold;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 7px;
  margin-bottom: 5px;
  font-size: 1.1rem;

  span {
    padding-right: 25px;
  }
}

.boom-panel-details {
  table {
    border-collapse: collapse;
  }

  td, th {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  td:last-child, th:last-child {
    padding-right: 0;
  }

  td:first-child, th:first-child {
    padding-left: 0;
  }
}

.boom-panel-actions {
  margin-top: 15px;
}