@use "~/src/scss/mixins/variables";
@use "~/src/scss/mixins/breakpoints";

header {
  height: 45px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
  padding: variables.$page-vertical-margin-mobile;
  background-color: #0072c6;
  color: #fff;

  @include breakpoints.pc() {
    padding: variables.$page-vertical-margin-pc;
  }

  &.environment-development {
    background-color: #00c61e;

    .app-title {
      font-size: 18px;
    }
  }

  &.environment-staging {
    background-color: #c6008d;

    .app-title {
      font-size: 18px;
    }
  }

  svg {
    fill: #fff;
  }

  .site-link {
    padding-left: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;

    &:first-of-type {
      margin-left: 40px;
    }
  }

  @include breakpoints.mobile {
    .site-link {
      display: none;
    }
  }

  input.search-box {
    width: 100%;
    max-width: 200px;
  }

  .header--userInfo > span {
    white-space: nowrap;
  }

  @include breakpoints.to-laptop {
    .app-title svg {
      display: none;
    }
  }
}

.app-title {
  font-size: 28px;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    height: 20px;
  }
}
