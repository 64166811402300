@use "~/src/scss/mixins/breakpoints";

.field-units-for-transport {
  border-collapse: collapse;
  margin-top: 6px;

  thead th {
    text-align: left;
    font-size: 14px;
    padding-bottom: 4px;
  }

  tr {
    vertical-align: top;

    td {
      padding: 3px 0;
    }

    &:last-child td {
      padding-bottom: 0;
    }
  }

  input, .post-input-designator {
    height: 42px !important;
  }

  .input-list {
    padding: 3px 6px;


    > div {
      display: flex;
      gap: 6px;
      flex-direction: column;

      @include breakpoints.from-laptop {
        gap: 0;
        flex-direction: row;
      }
    }
  }

  .remove-action > svg {
    margin-top: 8px;
  }
}
