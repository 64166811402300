@use "~/src/scss/mixins/breakpoints";

.field-measurements-options {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px 30px;

  select {
    width: 240px;
  }

  input {
    margin-right: 10px;
  }
}

.field-measurements-options {
  background: #e1e1e13b;
  padding: 5px 0;
  align-items: center;

  select {
    margin-left: 10px;
  }
}

.field-measurements-articles {
  display: flex;
  flex-direction: column;
}

.field-measurements-article {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.field-measurements-referenceArea {
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  span {
    margin-right: 20px;
  }

  input {
    width: 100px;
  }
}

.sum-article-average {
  justify-content: flex-end;
}

/* for mobile */
@include breakpoints.to-laptop {
  .field-measurements-article {
    display: grid;
    grid-template-columns: repeat(4, auto);

    .field-measurements-referenceArea {
      flex-direction: column;
    }
  }
}

@include breakpoints.mobile {
  .field-measurements-article {
    grid-template-columns: repeat(2, auto);
  }

  .field-measurements-options {
      display: flex;
      flex-direction: column;
  }
}
