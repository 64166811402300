.dialog-move-unit-content {
}

.dialog-move-unit-content-options {
  display: flex;
  flex-direction: column;

  label {
    margin: 5px 0;
  }
}

.dialog-move-unit-input {
  margin-top: 20px;

  input {
    display: block;
  }
}
