.send-sms {
  textarea {
    overflow-y: hidden;
  }

  .length {
    float: right;
    margin: -17px 7px 0 0;
    font-size: 0.85rem;
    font-style: italic;
  }

  button {
    float: right;
  }
}
