@use "~/src/scss/mixins/breakpoints";

.unitlist-item {
  display: flex;
  border-bottom: 1px solid #cdcdcd;
  padding: 15px 0;
  margin: 0 -20px;
  flex-wrap: wrap;

  @include breakpoints.from-laptop {
    flex-wrap: nowrap;
  }

  &:last-child {
    border-bottom: none;
  }

  h4:first-child {
    margin-top: 0;
  }

  .details-panel-item {
    flex-grow: 1;
    padding: 0 20px;
    margin-top: 20px;
    flex-basis: 0;

    @include breakpoints.from-tablet {
      margin-top: 0;
    }
  }

  .state-history {
    td:first-child {
      width: 180px;
    }
  }

  .details-action-list {
    display: flex;
    flex-direction: column;

    @include breakpoints.from-tablet {
      align-items: flex-end;
    }

    .dropdown-button {
      margin: 3px 0;

      button {
        min-width: 150px;
      }

      &:first-child {
        margin-top: 0;
      }

      span {
        white-space: nowrap;
      }
    }
  }
}

.unitlist-item--selected {
  border-left: 6px solid #008eff7d;

  > div:first-child {
    margin-left: calc(20px - 6px);
  }
}

.details-key-value-field {
  .indicator.inactive {
    opacity: 0.1;
  }
}

.unitlist-images {
  margin: 0 20px;
  cursor: pointer;

  @media (min-width: 620px) {
    max-width: calc(100vw - 230px);
  }
}

.img-stack {
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 400px;
  max-width: 88vw;
  height: 300px;

  &:before {
    top: 4px;
    z-index: -10;
  }

  &:before, &:after {
    content: "";
    border-radius: 3px;
    width: 100%;
    height: 295px;
    position: absolute;
    border: 10px solid #fff;
    left: 0;
    box-sizing: border-box;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transition: 0.3s all ease-out;
  }

  &:after {
    top: 8px;
    left: 3px;
    z-index: -20;
  }
}

.img-stack [role="img"], .img-stack video {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
}

.boom-listing {
  margin-top: 10px;
}

svg.unitlist-images--default {
  width: 80%;
  height: 80%;
  fill: #f2f2f2;
  left: 10%;
  top: 10%;
  position: absolute;
}

.unitlist-item {
  @include breakpoints.tablet {
    .unitlist-images {
      margin-bottom: 20px;
      order: 1;
    }

    .details-panel-item.details-action-list {
      order: 2;
      min-width: calc(100vw - 660px + 180px);
    }

    .details-panel-item {
      order: 3;
    }
  }
}

.error-image-row {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
  position: absolute;
  width: 800px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    margin-right: 8px;
    cursor: pointer;
  }

  div[role='img'] {
    width: 128px;
    height: 72px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #dfdfdf;
    border: 1px solid #d7d7d7;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px 0 rgba(232,43,43, 0.4);
  }

  70% {
    box-shadow: 0 0 10px 10px rgba(232,43,43, 0);
  }

  100% {
    box-shadow: 0 0 10px 0 rgba(232,43,43, 0);
  }
}

.unitlist-item .indicator:not(.inactive) {
  fill: red;
  animation: pulse;
  animation: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
