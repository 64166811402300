@use "~/src/scss/mixins/breakpoints";

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 15px;

  @include breakpoints.from-laptop {
    overflow: inherit;
  }

  .pending-request {
    padding-top: 10px;
    padding-left: 10px;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      padding-bottom: 5px;
    }
  }

  tr {
    line-height: 25px;

    &.row-deleted {
      font-style: italic;

      > td {
        opacity: 0.5;
      }
    }
  }


  th {
    white-space: nowrap;

    &.sortable {
      cursor: pointer;
    }

    svg {
      height: 14px;
      width: 12px;
      margin-left: 10px;
      fill: #eaeaea;
    }

    &.asc svg path.sort_svg__up {
      fill: #000;
    }

    &.desc svg path.sort_svg__down {
      fill: #000;
    }
  }


  tbody tr {
    transition: background-color 50ms linear;

    &:nth-child(2n+1) {
      background-color: rgba(#eaeaea, 0.5);
    }

    &:hover {
      background-color: #cdcdcd;
      color: #fff;
    }
  }

  td, th {
    text-align: left;
    padding: 10px;

    @include breakpoints.from-tablet {
      padding: 1px 10px;
    }

    &.collapsable-content-cell {
      padding: 0 10px;
    }

    @include breakpoints.laptop {
      &.narrow {
        padding-right: 4px;
        padding-left: 4px;
      }
    }

    .flex-center {
      display: flex;
      justify-content: center;
    }
  }

  td svg {
    width: auto;
    height: 16px;

    &:hover {
      fill: blue;
    }

    &[disabled] {
      opacity: 0.1;
    }
  }

  tfoot {
    td {
      font-weight: bold;
    }
  }
}

table.no-side-padding {
  td, th {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.list-no-hits {
  padding: 0 20px;
}

table.full-width {
  width: 100%;
}

table.normal-width {
  width: inherit;
}

.cell-action {
  color: #2f2f2f;
  width: 32px;

  fill {
    color: #2f2f2f;
  }
}

.text-table {
  border-collapse: collapse;
}

.css-table {
  display: table;

  > div {
    display: table-row;

    > span {
      display: table-cell;
      padding: 0 30px;

      &.first-child {
        padding-left: 0;
      }

      &.last-child {
        padding-right: 0;
      }

      &.right {
        text-align: right;
      }
    }
  }

  .css-table-header {
    font-weight: bold;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.collapsable-content {
  padding: 15px 0;
}

.collapsable-content-toggle {
  cursor: pointer;

  svg {
    fill: #7b7b7b;
  }
}

td.wrap {
  white-space: pre-line;
}

.list-offers tr.inactive {
  font-style: italic;
  opacity: 0.65;
}
