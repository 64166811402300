@use "~/src/scss/mixins/breakpoints";

.order-transport-details {
  display: flex;
  justify-content: space-between;  
  @include breakpoints.mobile {
    flex-direction: column-reverse;
  }
}

.order-transport-details .details-panel-item {
  display: flex;
  flex-direction: column;



  > div {
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }
  }

  .addressView, .kolliview {
    min-width: 320px;
  }

  @include breakpoints.from-laptop {
    flex-direction: row;

    > div {
      margin-left: 40px;
      margin-top: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.order-transport-list {
  margin-top: 40px;
}
