.customer-info-box {
    ul.wide {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 3px;
    }

    .info {
        margin-top: 1rem;
    }
}
