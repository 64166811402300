.customer-company-selector {
  li {
    padding: 2px 5px;
    margin: 3px -5px;
    cursor: pointer;
    &:hover {
      background-color: #eaeaea;
    }
  }
}

