.search-page {
  li.result-item {
    padding: 8px 10px;
    display: flex;
    align-content: center;
    margin: 0 -10px;

    &:nth-child(2n+1) {
      background-color: #f5f5f5
    }
  }

  li.result-item > *, li.result-header > * {
    margin-right: 20px;
    display: inline-block;
  }

  li.result-header {
    font-weight: 500;
  }

  .result-title-link-container {
  }

  .result-title {
    margin-right: 20px;
    display: inline-block;
    width: 140px;
  }

  .result-link {
    width: 75px;
    text-align: right;
    display: inline-block;
  }

  .result-hit {
    width: 190px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .result-date {
    width: 160px;
    text-align: right;
  }

  .result-value {
    width: 160px;
  }
}
