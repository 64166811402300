.typeahead-popup {
  position: absolute;
  background: white;
  padding: 2px 0px;
  z-index: 1;
  border: 1px solid #cdcdcd;
  min-width: min(200px, 90vw);
  max-width: 90vw;
  margin-top: -1px;
  max-height: min(200px, 90vh);
  overflow-y: auto;

  &.hidden {
    display: none;
  }

  > li {
    padding: 3px 7px;
    margin: 1px 0;
    cursor: pointer;

    &[aria-selected='true'] {
      background: #e2e2e2;
    }
  }

}
