.dropdown-button {
  position: relative;

  .carret {
    transform: rotate(180deg);
    transition: transform ease-in-out 100ms;

    &.expanded {
      transform: rotate(0deg);
    }
  }

  ul {
    background-color: #fafafa;
    border: 1px solid #cdcdcd;
    position: absolute;
    min-width: 110px;
    right: 0;
    z-index: 15;
    margin-top: -1px;

    li {
      cursor: pointer;
      padding: 7px 5px;

      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  button {
    min-height: 33px;
    justify-content: space-between;

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


.button {
  background-color: #eeeeeed9;
  border: 1px solid #dedede;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms background-color;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    background-color: #fff;
    border-color: #b2b2b2;
  }

  &.active {
    color: #fff;
    fill: #fff;
    background-color: #004bff;
    border: 1px solid #00b1ff;

    &:hover:not([disabled]) {
      background-color: #00b1ff;
      border-color: #00b1ff;
    }
  }

  &[disabled] {
    color: #bebebe;

    svg {
      fill: #bebebe;
      stroke: #bebebe;
    }
  }
}

.button-small {
  font-size: 0.85rem;
  padding: 4px 7px;

  svg {
    height: 12px;
  }
}

a.button {
  display: inline-block;
  text-decoration: none;
  color: #2f2f2f;

  span {
    margin-left: 5px;
    vertical-align: text-top;
  }
}

.buttons {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;

  &.right {
    justify-content: flex-end;
  }

  > button, > div.dropdown-button {
    margin: 0 5px;

    @mixin from-tablet {
      margin: 0 10px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
