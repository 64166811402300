.page-due-invoices {
  .invoice-group {
    margin-bottom: 20px;
  }

  .invoice-group-header {
    background-color: #808080;
    color: #fff;
    padding: 10px;
    display: flex;

    @media (max-width: 768px) {
      justify-content: space-between;

      > span:nth-child(2) {
        display: none;
      }
    }
  }

  .invoice-group-header span {
    margin-left: 20px;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 768px) {

    .invoice-group-header span:last-child {
      flex-grow: 1;
      width: 100%;
      text-align: right;
    }

    .invoice-group-header span:first-child {
      min-width: 300px;
    }
  }

  .invoice-item {
    background: #f9f9f9;
    padding: 10px;
    border-bottom: 1px solid #ababab;
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      span {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .invoice-item span:first-child {
    min-width: 135px;
  }

  .invoice-item span, .invoice-item a {
    margin-right: 20px;
  }

  .invoice-item span:last-of-type {
    width: 80px;
    margin-right: 0;

    @media only screen and (min-width: 768px) {
      text-align: right;
    }
  }

  .f-grow {
    flex-grow: 1;
  }
}
