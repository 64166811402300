@use "~/src/scss/mixins/breakpoints";

.customer-selector {
  max-width: 300px;

  .alphabeth {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
    overflow: scroll;

    @include breakpoints.from-laptop {
        overflow: inherit;
    }

    [aria-selected="true"] {
      font-weight: 700;
    }

    li {
      padding-left: 2px;
      padding-right: 2px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  select {
    width: 100%;
  }
}
