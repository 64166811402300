@use "~/src/scss/mixins/breakpoints";

.page-card-usage {
  .panel {
    padding: 0;
  }

  .header {
    display: grid;
    align-items: center;
    background-color: #eaeaea;
    padding: 5px 20px;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
    grid-template-columns: 1fr 1fr 25px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "date amount toggle" "text text toggle";
    gap: 0 20px;



    @include breakpoints.from-tablet {
      gap: 40px;
      grid-template-columns: 150px 100px auto 25px;
      grid-template-rows: 1fr;
      grid-template-areas:
        "date amount text toggle";
    }

    > span:first-child() {
      grid-area: date;
    }

    > span:nth-child(2) {
      grid-area: amount;
    }

    > span:nth-child(3) {
      grid-area: text;
      grid-row-start: 2 span 2;
    }

    > svg {
      grid-area: toggle;
      grid-column-start: 1 span 2;
    }
  }

  .item-description {
    white-space: pre-line;
  }

  .item-amount {
    text-align: right;
  }

  .post {
    display: grid;
    gap: 5px 10px;
    grid-template-columns: 1fr 1fr 40px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "type type type" "amount tax delete";
    margin-bottom: 20px;

    @include breakpoints.from-tablet {
      grid-template-columns: 350px 100px 110px 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "type amount tax delete";
      margin-bottom: 5px;
    }

    > input, > select, > div input, > div select, .post-input-designator {
      min-width: inherit !important;
      height: 40px;
    }

    .button-small {
      max-width: 40px;
    }

    .posting-type {
      grid-area: type;
    }

    .posting-amount {
      grid-area: amount;
    }

    .posting-tax {
      grid-area: tax;
    }

    .posting-delete {
      grid-area: delete;
    }
  }

  .body {
    background-color: rgba(234, 234, 234, 0.5);
    padding: 20px;

    > .edit-form {
      display: grid;
      grid-template-rows: auto auto auto auto;
      gap: 20px;

      @include breakpoints.from-tablet {
        grid-template-columns: 400px 600px auto 100px;
        grid-template-rows: auto;
        gap: 40px;
      }
    }
  }

  .add {
    margin-top: 10px;
    display: flex;
    align-items: center;

    button {
      height: 30px;
      margin-right: 20px;
    }

    span {
      color: red;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    @include breakpoints.from-tablet {
        flex-direction: column;
        justify-content: inherit;
    }

    button {
      margin-bottom: 5px;
    }
  }

  .attachment2 {
    label {
      display: block;
    }

    > input {
      min-width: inherit !important;
      width: inherit !important;
    }
  }

  .reconsile {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
