.repeat-field {
  min-width: 330px;

  .remove-action {
    cursor: pointer;
    margin-left: 10px;
  }

  .add-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .field-units-for-transport {
      min-width: 200px;
  }
}

.form-field-component.has-errors .repeat-field {
  background: repeating-linear-gradient(45deg, #ff000017 1%, #ffffff 3%)
}
