input {
  &.input-with-post-designator {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.input-with-pre-designator {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.pre-input-designator, .post-input-designator {
  display: flex;
  align-items: center;
  padding: 0 5px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  height: 36px;
}

.pre-input-designator {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-right: -1px;
  border-right: none !important;
}

.post-input-designator {
  margin-right: 5px;
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}

.input-with-fix--disabled{
  opacity: 0.5;
}