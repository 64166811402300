@use "~/src/scss/mixins/breakpoints";

.alert-bar-item {
  padding: 4px 13px 4px 13px;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;

    &:first-child {
      margin-right: 10px;
      width: 1.5rem;
      height: auto;
    }
  }

  svg.action {
    cursor: pointer;
    margin-left: 10px;
    align-self: start;
  }
}

.alert-bar-type-0 {
  background-color: #ff6464;
  border: 1px solid #ff3f3f;
  color: #fff;

  svg {
    fill: #fff;
    min-width: 15px;
  }
}

.alert-bar-type-1, .alert-bar-type-3 {
  background-color: #fff5cb;
  border: 1px solid #ffeb97;
}

.alert-bar-type-2 {
  background-color: #a1ffb4;
  border: 1px solid #7df595;
}

.alert-bar-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.alert-bar-title {
  font-weight: bold;
}

.alert-bar-message {
  font-style: italic;

  @include breakpoints.mobile {
    font-size: 0.94rem;
  }
}
