.edit-form .field-ral-color {
  display: flex;
  align-items: center;

  input[type="text"] {
    min-width: 110px !important;
    width: 110px !important;
  }
}
.field-ral-color-info {
  align-items: center;
}

.field-ral-color-info-color {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 10px;
}
