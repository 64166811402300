.month-navigator {
  display: flex;
  align-items: center;

  span {
    margin: 0 20px;
    font-size: 1.17em;
    font-weight: bold;
  }

  button {
    background-color: transparent;
    border: 1px solid #a5a4a4;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }

    &.previous svg {
      transform: rotate(180deg);
    }
  }
}
