.field-files-zone {
  width: 100%;
  height: 120px;
  border: 1px solid #fefefe;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;

  &.is-over {
    border-color: #cdcdcd;
    background-color: rgba(222, 222, 222, 0.2901960784313726);
  }
}

.field-files {
  ul {
    list-style-type: none;
    width: 100%;
    display: table;
    table-layout: auto;
    background-color: #f0f0f0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      border-bottom: 1px solid #dedede;
      padding-bottom: 3px;
      &:last-child {
          border-bottom: none;
      }
    }

    img {
      width: 130px;
      margin-right: 5px;
      height: 84px;
      object-fit: contain;
    }

    li > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 304px;
    }

    li > svg {
      width: 130px;
      height: 84px;
      padding: 20px;
      margin-right: 5px;
    }

    .filename {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }

    .size {
      font-size: 0.75rem;
      font-style: italic;
      margin-top: 5px;
    }

    .remove {
      margin: 0 10px;
      cursor: pointer;
    }

    .remove svg {
      width: 16px;
      height: 16px;
    }
  }
}
