.toast {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  border-radius: 4px;
}

.success-toast {
  border-left: 12px solid rgb(54, 179, 126);
  background: rgb(227, 252, 239);
  color: rgb(0, 102, 68);
}

.info-toast {
  border-left: 12px solid rgb(38, 132, 255);
  background: white;
  color: rgb(80, 95, 121);
}

.warning-toast {
  border-left: 12px solid rgb(255, 171, 0);
  background: rgb(255, 250, 230);
  color: rgb(255, 139, 0);
}

.error-toast {
  border-left: 12px solid rgb(255, 86, 48);
  background: rgb(255, 235, 230);
  color: rgb(191, 38, 0);
}