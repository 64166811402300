.dashboard-page {
  background-color: white;
  padding: 1rem;
  font-family: 'AR One Sans', sans-serif;

  .details-key-value-field .field-title {
    width: 90px;
  }

  h1, h2 {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 1rem;
  }

  .video-stream-1 {
    width: 100%;
    max-width: inherit;
    margin: 0;
    max-height: inherit;
    border: 1px solid #efefef;
  }


  .chart {
    border: 1px solid #efefef;
    margin-top: 1rem;
    background: #fafafa;

    .apexcharts-text.apexcharts-xaxis-label {
      font-weight: normal;
      font-family: 'AR One Sans', sans-serif !important;
    }
  }

  .header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .dashboard-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  .side-area {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  table {
    width: 100%;

    th {
      text-align: left;

      &.right {
        text-align: right;
      }
    }
  }

  .box {
    border: 1px solid #efefef;
    padding: 1rem;
    background: #fafafa;



    &.link {
      border-color: #cacaff;

      &:hover {
        background-color: #ededed;
        cursor: pointer;
      }

      svg {
        margin-right: 1rem;
      }
    }
  }

  .w2 {
    grid-column-start: span 2;
  }

  .w4 {
    grid-column-start: span 4;
  }

  .info-button-container {
    display: flex;
    gap: 2rem;
    //margin: 2rem 0;
    color: #fff;
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    min-height: 96px;
    border: 1px solid #efefef;
    padding: 0.5rem;
    display: block;
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  .info {
    font-size: 0.85rem;
    font-style: italic;
    opacity: 0.8;
    float: right;
  }

  a.info {
    margin-top: 1rem;
    opacity: 1;

    &:visited {
      color: rgb(0, 0, 238);
    }
  }

  .box table {
    td:first-child, th:first-child {
      padding-left: 0;
    }

    td:last-child, th:last-child {
      padding-right: 0;
    }

    .has-work-order {
      opacity: 0.7;
      font-style: italic;
    }
  }

  table.table-hand {
    tbody tr {
      cursor: pointer;
    }
  }

  .link-area {
    display: flex;
    gap: 2rem;
    justify-content: center;


    div {
      display: flex;
      justify-content: center;

      svg {
        margin-right: 3px;
      }
    }
  }
}
