.file-list {
  background-color: #fdfdfd;

  .file-list-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eaeaea;
    height: 74px;

    &:nth-child(2n+1) {
      background-color: #f3f2f2;
    }

    img {
      height: 64px;
    }

    audio {
        margin-left: 10px;
    }
  }

  .file-list-preview {
    padding-right: 15px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 80px;
  }

  .file-list-details {
    display: flex;
    flex-direction: column;
  }

  .file-list-name {
    margin-bottom: 5px;
  }

  .file-list-size {
    font-style: italic;
    font-size: .85rem;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .file-list-preview-image {
    cursor: pointer;
  }
}
