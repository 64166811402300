@use "~/src/scss/mixins/breakpoints";

.state-changes-tab {

  @include breakpoints.mobile {
    .workorder-change {
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      & > :empty {
        display: none;
      }
    }

    .unit-change {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
    }

    .unit-change {
      padding-left: 15px;
    }
  }

  @include breakpoints.from-tablet {

    .workorder-change, .unit-change {
      padding: 2px 0;
    }

    .workorder-change {
      font-weight: bold;
    }

    .state-change-unit {
      display: inline-block;
      width: 75px;
      padding-right: 5px;
    }

    .state-change-from, .state-change-to {
      display: inline-block;
      width: 150px;
      padding-right: 5px;
    }

    .state-change-date {
      display: inline-block;
      width: 220px;
      text-align: right;
      padding-right: 5px;
    }
  }
}
