@media only screen and (max-width: 768px) {
  nav.menu {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 1001; // over kart
    font-size: 18px;
    overflow-y: auto;
    display: none;

    &.menu--open {
      display: block;
    }

    a {
      display: block;
    }

    .nav-level1-group {
      padding: 8px 0;
      border-bottom: 1px solid #dedede;
    }

    .nav-level2-group {
      padding-left: 20px;
    }

    .nav-menu-item {
      display: flex;
      padding: 10px 25px;
    }

    .nav-menu-item-caret {
      display: none;
    }

    .mobile-menu-close {
      position: fixed;
      right: 20px;
      top: 20px;
      background: transparent;
      border: none;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  nav.menu {
    display: flex;
    background: #f5f5f5;
    padding: 0px 5px;
    flex-wrap: wrap;

    span, a {
      text-decoration: none;
      color: #000;
      white-space: nowrap;
    }

    .mobile-menu-close {
      display: none;
    }

    .nav-level1-group {
      position: relative;

      &:focus-within, &:hover {
        .nav-level2-group {
          display: block;
        }

        .nav-menu-item-open {
          display: inline-block;
        }

        .nav-menu-item-closed {
          display: none;
        }
      }
    }

    .nav-menu-item {
      padding: 15px 20px;
      display: inline-block;
      width: 100%;

      &:focus, &:hover {
        background-color: white;
        outline: none;
      }
    }

    .nav-level1-group > .nav-menu-item {
      border-right: 1px solid #e8e8e8;
      height: 50px;
      display: block;

      &.nav-menu-item--open {
        background: #b7b7b7;

        span, > a {
          color: #fff;
        }
      }
    }

    .nav-level2-group {
      position: absolute;
      background: #f5f5f5;
      z-index: 4;
      left: 0;
      top: 50px;
      border: 1px solid #e8e8e8;
      border-top: none;
      display: none;
    }

    .nav-menu-item-caret {
      margin-left: 5px;
    }

    .nav-menu-item-open {
      display: none;
    }

    .unread-count--bouble {
      color: #fff;
    }

    .nav-menu-item--active:not(:active) {
      background-color: #1d5d90;

      span, a, .nav-menu-item-caret {
        color: #fff;
      }
    }
  } 
}



nav {
  .unread-count {
    font-size: 12px;
    margin-left: 5px;
  }

  .unread-count--bouble {
    background-color: #727272;
    padding: 4px 7px;
    border-radius: 50%;
    font-size: 12px;
    color: white;
    margin-left: 5px;
    animation: colorchange 1s infinite;
  }

  .unread-count--box {
    padding: 2px 4px;
    color: white;
    border: 1px solid #c8c7c7;
    color: #4b4b4b;
  }
}


@keyframes colorchange {
  0% {
    background-color: #727272;
  }

  50% {
    background-color: #000;
  }

  100% {
    background-color: #727272;
  }
}
