@use "~/src/scss/mixins/breakpoints";

.details-key-value-field {
  display: flex;
  margin-top: 10px;
  word-break: break-all;
  white-space: nowrap;

  &.long {
    flex-direction: column;

    .field-title {
      margin-bottom: 3px;
    }

    .field-value {
      font-style: italic;
      margin-top: 4px;
      padding-left: 10px;
      width: 100%;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  @include breakpoints.from-tablet {
    word-break: break-word;
  }

  .field-title {
    width: 180px;
    flex-shrink: 0;
    padding-right: 10px;
    font-weight: 500;

    &:after {
      content: ':'
    }
  }

  .wrap {
    white-space: pre-line;
  }
}

.field-value {
  @include breakpoints.from-tablet {
    &.right {
      margin-left: auto;
    }
  }

  span.error {
    color: red;
  }
}
