﻿@use "mixins/breakpoints";

.panel {
  border: 1px solid #e4e4e4;
  background-color: white;

  @include breakpoints.from-tablet {
    border-radius: 5px;
    box-shadow: 1px 1px 20px 5px #dedede;
  }
}

.panel-padding {
  padding: 20px;

  h3, h4 {
    margin-top: 0;
  }
}

.panel-header-notification {
  padding: 10px 20px;
  background: #ff5959;
  color: #fff;
  display: flex;
  align-items: center;

  svg {
    fill: white;
  }

  @include breakpoints.from-tablet {
    border-radius: 5px 5px 0 0;
  }
}
