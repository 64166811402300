.customer-map .leaflet-container {
  height: calc(100vh - 390px);
  margin-top: 14px;
  width: 100%;
  position: relative;
  border: 5px solid white;
  outline: none;
}

.react-datepicker-popper{
    z-index: 401; // datovelger over kart
}

.customer-popup .leaflet-popup-content  {
  display: flex;
  flex-direction: column;
}