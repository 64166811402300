@use "~/src/scss/mixins/breakpoints";
@use "~/src/scss/mixins/variables";

ul[role="tablist"] {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
}

li[role="tab"] {
  color: #fff;
  background-color: #8c8c8c;
  padding: 15px;
  border: 1px solid #eaeaea;
  border-left: none;
  flex: 1 0 auto;

  @include breakpoints.from-tablet {
    color: #2f2f2f;
    background-color: white;
    flex-grow: 0;

    &:first-child {
      border-radius: 5px 0px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 5px 0px 0px;
    }

    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }

  &[aria-selected="true"] {
    background-color: #383838;
    color: #fff;

    @include breakpoints.from-tablet {
      background-color: #4b4b4b;
    }

  }

  @include breakpoints.from-tablet {
    &:first-child span {
      padding: 0 10px;
    }
  }

  .bouble {
    background: #f0f0f0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    padding: 4px;
    color: #4c4c4c;
    font-size: 11px;
    line-height: 16px;
    border: 1px solid #aaaaaa;
  }
}

div[role="tabpanel"] {
  background-color: #fff;
  box-shadow: 1px 1px 20px 5px #dedede;
  border-radius: 0 0 5px 5px;
  padding: variables.$page-vertical-margin-mobile;

  @include breakpoints.from-tablet() {
    padding: variables.$page-vertical-margin-pc;
  }

  .list-area {
    margin-top: 0;
  }
}
