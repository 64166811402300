@use "~/src/scss/mixins/breakpoints";

.workorder-customer-selector {
  padding: 20px;
  display: flex;
  flex-direction: column;

  @include breakpoints.from-tablet {
    flex-direction: row;
  }


  .customer-selector-area {
    flex-grow: 1;
    width: 100%;
  }

  .reference-selector-area {
    margin-top: 25px;

    @include breakpoints.from-tablet {
      margin-top: 0;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 250px;
      padding-left: 20px;
    }

    input {
      min-width: inherit !important;
    }

    button {
      margin-top: 12px;
    }
  }

  h3 {
    margin-top: 0;
  }

  .reference-description {
    > div {
      margin-top: 12px;

      span:first-child {
        font-weight: bold;
        display: block;
        margin-bottom: 6px;
      }
    }
  }

  .create-link {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    &:hover svg {
      fill: #216ba5;
    }

    svg {
      margin-right: 5px;
    }
  }
}
