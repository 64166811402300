@use "~/src/scss/mixins/breakpoints";

.list-navigator {
  position: absolute;
  right: 0;
  display: flex;
  padding-right: 3px;
  padding-top: 3px;

  @include breakpoints.from-laptop {
    padding-right: 25px;
    padding-top: 22px;
  }

  .list-navigator-item {
    margin-right: 6px;

    > a, > span {
      width: 35px;
      height: 35px;
      border: 1px solid #cacaca;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color linear 50ms;

      &:hover {
        background: #ffffff;
      }
    }

    > span {
      opacity: 0.2;
    }

    svg.up {
      transform: rotate(-90deg);
    }

    svg.down {
      transform: rotate(90deg);
    }
  }
}
