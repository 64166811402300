.boom-removal-page {
  .box {
    display: block;
    height: 175px;
    width: 200px;
    border: 1px solid #e0e0e0;
    padding: 20px;
    box-shadow: 4px 4px 15px 1px #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    svg {
      height: 40px;
      width: 40px;
      margin-bottom: 10px;
    }
  }

  .boom-unit {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #ededed;
    border-bottom-style: dashed;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    > * {
      margin-right: 60px;
      min-width: 200px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .unit-articles {
    display: flex;
    flex-direction: column;

    > label {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 span {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .unit-weight-input > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-weight {
    display: flex;

    button {
      margin-left: 10px;
    }
  }

  .unit-weight-list tr td.weight {
    text-align: right;
    padding-left: 60px;
    padding-right:20px;
  }

  tfoot {
    font-weight: bold;
  }

  .image-list {
    ul {
      display: flex;
    }

    li {
      margin-right: 5px;
    }

    div[role=img] {
      width: 213px;
      height: 120px;
      background-size: cover;
      border: 1px solid #f4f4f4;

      &:last-child {
        margin-right: 0
      }
    }
  }

  .other-list {
      margin-top: 20px;
      span {
          min-width: 160px;
          padding-right: 20px;
          display: inline-block;
          margin-bottom: 8px;
      }
  }
}
