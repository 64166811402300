@use "~/src/scss/mixins/breakpoints";

.field-phonenumber {
  width: 100%;

  @include breakpoints.from-tablet {
    min-width: 400px;
  }

  select {
      margin-right: 0;
      padding: 0;
      padding-left: 2px;
      font-size: 0.95rem;
  }

  input {
      flex-grow: 1;
  }
}
