@use "~/src/scss/mixins/breakpoints";

.payslip-container {
  @include breakpoints.mobile {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .payslips {
      order: 1;
    }
  }

  @include breakpoints.from-tablet {
    display: grid;
    grid-template-columns: auto 300px;
    grid-gap: 2rem;
    margin: 2rem 0 1rem 0;
  }
}

.notifications {
  li {
    border: 1px solid #e5e5e5;
    background-color: #feffd6;
    color: #505050;
    padding: 2px 3px;
    font-style: italic;
  }

  &:not(:empty) {
    margin-bottom: 1rem;
  }
}

.details {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr td:first-child {
    font-weight: bold;
  }

  button {
    margin-top: 1rem;
  }
}

.payslip {
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  table {
    border-collapse: collapse;
  }

  h4 {
    margin: 0;
  }

  hr {
    margin: 5px 0 5px 0;
  }
}

.payslip-row {
  display: grid;
  grid-template-columns: 130px 100px auto;
  gap: 1rem;

  &.payslip-row-italic {
    font-style: italic;
    opacity: 0.4;
  }
}

span.right {
  text-align: right;
}
