.field-checkbox-item {
  margin: 7px 0;
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  label {
    margin-left: 5px;
    cursor: pointer;
  }
}
