@use "~/src/scss/mixins/breakpoints";

.search-box {
  border: none;
  padding: 4px 6px;
  font-family: 'Roboto';
  color: #2b2b2b;

  @include breakpoints.from-tablet {
    border-radius: 5px;
  }

  &::placeholder {
    font-family: 'Roboto';
  }
}
