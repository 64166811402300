@use "~/src/scss/mixins/breakpoints";

.day-navigator {
  padding: 20px;
}

.day-wo-item {
  display: flex;
  padding: 3px 20px;
  line-height: 1.3rem;

  > a:nth-child(1) {
    flex-basis: 70px;
  }

  > a:nth-child(2) {
    flex-basis: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @include breakpoints.from-tablet {
    > span:nth-child(3), > span:nth-child(4) {
      flex-basis: 115px;
      text-align: right;
      margin-right: 20px;
    }
  }

  .day-unit-list {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-between;

      > span:nth-child(1) {
        flex-basis: 70px;
        min-width: 70px;
      }

      > span:nth-child(2) {
        text-align: right;
        flex-basis: 50px;
        min-width: 50px;
        margin-right: 10px;
      }

      > span:nth-child(3) {
        text-align: right;
        flex-basis: 90px;
        min-width: 90px;
      }
    }
  }

  .wo-preview {
    display: none;
    position: absolute;
  }
}

.day-wo-item:nth-child(2n) {
  background: #f7f7f7;
}

.day-wo-item:hover {
  background: #e2e2e2;
}

.day-header {
  background-color: #606060;
  padding: 5px 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  margin: 5px 0;
}

.day-unit-list > div {
  position: relative;
}

.day-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg.svg-inline--fa {
    width: 32px;
    height: 32px;
    cursor: pointer;
    padding-right: 20px;
  }
}

@include breakpoints.mobile {
  .day-wo-item {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: auto 152px;
    grid-gap: 2px 15px;

    > a:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    > a:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }

    > span:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }

    > span:nth-child(4) {
      grid-column: 1;
      grid-row: 4;
    }

    > div:nth-child(5) {
      grid-column: 2;
      grid-row: 1 / 4;
    }

    .day-unit-list {
      a {
        display: none;
      }
    }
  }

}
